//inline svg
.isvg{
  display: inline-block;
}

.img-wrapper-overflow{
  overflow: hidden;
  max-width: 100%;

  .img{
    margin: 0 -100%;
    position: relative;
  }
}
