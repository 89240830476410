// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  font-weight: $font-weight-normal;
  letter-spacing: 1px;
}

.btn-outline-black{
  border-width: 1px;

  @include button-outline-variant(#000000, #ffffff, $blue, $blue);
}

.btn-primary {
  @include button-variant($blue, $blue, #ffffff);
}

.btn-outline-primary {
  background: #ffffff;

}

//
// Alternate buttons
//
.btn-white-blue{
  @include button-variant(#ffffff, #ffffff, $blue, $blue, $blue, #ffffff, $blue, $blue, #ffffff);
}

.btn-white-yellow{
  @include button-variant(#ffffff, #ffffff, $blue, $yellow, $yellow, #ffffff, $yellow, $yellow, #ffffff);
}
