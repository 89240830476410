.old-browser-message{
  position: fixed;
  text-align: center;
  z-index: 1049;
  width: 100%;
  top: 100px;
  left: 0;
  right: 0;

  .container{
    max-width: 970px;
  }

  .noti {
    margin: 47px 0;
    border: 1px solid #777;
    box-shadow: 0 0 8px #888;
    background: #FFF4F4;
  }

  .whatnow {
    font-size: 26px;
    line-height: 1.1em;
    text-align: center;
    margin: 13px 25px;
  }

  .logos {
    width: 100%;
    border: none;

    td {
      vertical-align: top;
      padding: 10px;
      border: none;
      background: none;
      width: 33%;
    }

    a{
      border: 1px dotted transparent;
      color: #e25600;
      display: block;
      max-width: 155px;
      margin: 0 auto;

      &:hover, &:active{
        border-color: rgb(170,170,170);
      }

      img{
        margin-bottom: 5px;
      }

      .bro{
        white-space: nowrap;
        text-decoration: underline;
        font-size: 20px;
      }

      .vendor{
        color: #aaa;
        text-align: center;
        font-size: 11px;
        display: block;
      }
    }
  }

  a {
    color: #e25600;
  }
}

