// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 { @include responsive-font-size($h1-font-size, $h1-font-size-desktop); }
h2, .h2 { @include responsive-font-size($h2-font-size, $h2-font-size-desktop); }
h3, .h3 { @include responsive-font-size($h3-font-size, $h3-font-size-desktop); }
h4, .h4 { @include responsive-font-size($h4-font-size, $h4-font-size-desktop); }
h5, .h5 { @include responsive-font-size($h5-font-size, $h5-font-size-desktop); }
h6, .h6 { @include responsive-font-size($h6-font-size, $h6-font-size-desktop); }


//Links

// Blockquotes
.blockquote {

  .quote{
    line-height: 1.25;
    font-weight: $font-weight-light;
    margin-bottom: 16px;

    @include media-breakpoint-up(md){
      line-height: 1.17;
      margin-bottom: 21px;
    }
  }
}

.blockquote-footer {
  display: block;
  color: $blockquote-small-color;

  &::before {
    content: "";
  }

  cite{
    font-style: normal;
    @include responsive-font-size($h5-font-size, $h5-font-size-desktop)
  }
}

a{
  transition-property: color, background-color;
  transition-duration: $transition-duration;
}

// Contextual colors
body a.text-dark{
  @include hover-focus {
    color: $blue !important;
  }
}
