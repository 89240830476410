// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.
p { margin-bottom: $paragraph-margin-bottom; }

b, strong { font-weight: $font-weight-bold; }

//
// Forms
//

label {
  font-weight: $font-weight-bold;
}
