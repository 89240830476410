$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$white: #ffffff;
$gray-400:             #c0c0c0;
$gray-600:             #8d8d8d;
$black:    #000;


$blue:                  #00a2ff;
$primary:         $blue;
$dark:          $black;
$twitter: #00a2ff;
$linkedin: #0277b5;
$medium: #447ef7;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded:            false;
$enable-shadows:            false;
$enable-print-styles:       false;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
        (
            0: 0,
            1: ($spacer * .25),
            2: ($spacer * .5),
            3: $spacer,
            4: ($spacer * 1.5),
            5: rem-calc(30px),
            6: ($spacer * 2.25),
            7: ($spacer * 2.5),
            8: rem-calc(45px),
            9: rem-calc(50px),
            10: rem-calc(55px),
            11: rem-calc(60px),
            12: rem-calc(65px),
            13: ($spacer * 5),
            14: ($spacer * 5.5),
            15: ($spacer * 6),
            16: ($spacer * 6.5),
            17: ($spacer * 7)
        ),
        $spacers
);


// Body
//
// Settings for the `<body>` element.
$body-color:                $black;


// Links
//
// Style anchor elements.
$link-color:            $primary;
$link-hover-decoration: none;


// Paragraphs
//
// Style p element.



// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
    xs: 0,
    xsm: 460px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
    xs: 480px,
    sm: 540px,
    md: 720px,
    lg: 960px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           20px;
$container-gutter-width:           50px;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "ProximaNova", 'Proxima Nova', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-base:        $font-family-sans-serif;

//root font size = 16px
$font-size-base:              rem-calc(18px);
$font-size-base-desktop:      rem-calc(24px);
$font-size-sm:                rem-calc(14px);
$font-size-lg:                rem-calc(60px);

$font-weight-thin:            100;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-light;
$line-height-base:            1.44445;
$line-height-base-desktop:            1.33334;

$h1-font-size:                rem-calc(36px);
$h1-font-size-desktop:        rem-calc(60px);
$h2-font-size:                rem-calc(36px);
$h2-font-size-desktop:        rem-calc(60px);
$h3-font-size:                rem-calc(18px);
$h3-font-size-desktop:        rem-calc(24px);
$h4-font-size:                rem-calc(18px);
$h4-font-size-desktop:        rem-calc(24px);
$h5-font-size:                rem-calc(14px);
$h5-font-size-desktop:        rem-calc(18px);
$h6-font-size:                rem-calc(14px);
$h6-font-size-desktop:        rem-calc(18px);

$headings-margin-bottom:      0.7em;
$paragraph-margin-bottom:   $line-height-base * 1em;

//** By default, this inherits from the `<body>`.
$headings-font-weight:        $font-weight-thin;
$headings-line-height:        1.2;
$text-muted:                  #9b9b9b;

$blockquote-small-color:      inherit;
$blockquote-small-font-size:  $font-size-sm;
$blockquote-font-size:        inherit;


//rfs
$font-size-breakpoint: "md";


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-border-width:      2px;


$input-btn-padding-y-sm:      rem-calc(2px);
$input-btn-padding-x-sm:      1.65rem;

// Modals

$modal-dialog-margin:               20px;
$modal-dialog-margin-y-sm-up:       20px;

// Padding applied to the modal body
$modal-inner-padding:               rem-calc(13px);
$modal-content-border-width: 1px;
$modal-content-border-color: #979797;
$modal-content-box-shadow-xs: none;

$modal-header-border-width:         0;
$modal-footer-border-width:         0;

$modal-backdrop-bg:                 rgba($white, 0.88);
$modal-backdrop-opacity:  1;

$modal-md:                          714px;

