section{
  position: relative;

  .block-anchor{
    position: absolute;
    top: -92px;

    @include media-breakpoint-up(md){
      top: -155px;
    }
  }
}


.block-hero{
  .img{ margin-bottom: 40px; }

  @include media-breakpoint-up(sm){
    margin-bottom: 52px !important;
    .img{ margin-bottom: 57px; }
  }
}

.block-nowhere{
  .img{ left: -127px; }

  .nowhere-logo{
    margin-bottom: 46px;
  }

  .container-660{
    max-width: 490px;
    @include media-breakpoint-up(md){
      max-width: 660px + $container-gutter-width;
    }
  }

  @include media-breakpoint-up(sm){
    .img{ left: auto; }
  }

  @include media-breakpoint-up(md){
    .nowhere-logo{
      width: 100px;
    }

    .img{ height: 731px; }
  }
}

.block-change-world{
  .img1{
    @include media-breakpoint-up(sm){
      left: auto;
      margin: 0;
      float: right;
      height: 489px;
    }
  }



  .container-660{
    @include media-breakpoint-up(md){
      max-width: 660px + $container-gutter-width;
    }
  }

  .container-670{
    @include media-breakpoint-up(md){
      max-width: 670px + $container-gutter-width;
    }
  }

  .logo-wef{
    margin-bottom: 15px;
  }

  .logo-ted{ margin-bottom: 34px }

  @include media-breakpoint-up(md){
    .img1{ height: 731px; }

    .logo-wef{
      height: 156px;
      margin-bottom: 27px !important;
    }
    .logo-sb{
      height: 84px;
      margin-bottom: 48px !important;
    }
    .logo-ted{ height: 77px; }
  }

  @include media-breakpoint-up(xl){
    .img1{
      float: none;
      margin: 0 -100%;
    }
  }
}

.block-speaker{
  .img-wrapper-overflow{
    height: 489px;
  }
  .img1{
    top: -130px;
    left: -13px;
    margin: 0 -1219px;
  }

  .blockquote{
    margin-left: -5px;
    margin-right: -5px;
    .quote { letter-spacing: -0.4px; }
  }

  @include media-breakpoint-up(md){
    .blockquote{
      margin-left: auto;
      margin-right: auto;
      .quote { letter-spacing: normal; }
    }

    .img-wrapper-overflow{ height: auto; }
    .img1{
      height: 731px;
      top: auto;
      left: auto;
      margin: 0 -100%;
    }
  }
}

.block-author{
  @include media-breakpoint-up(md) {
    .container-720 { max-width: 720px + $container-gutter-width; }
  }

  .books{
    max-width: 254px;
    margin: 0 auto 65px;

    .img{
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
      margin-bottom: 30px;
    }

    .group3{
      img{
        margin-bottom: 20px;
      }
      .img2{ margin-right: 3px; }
      .img6{ margin-right: -2px; }
      .img9{ margin-right: -3px; }
    }



    @include media-breakpoint-up(md){
      max-width: 866px;
      .group1{
        margin-bottom: 13px;
        .img{ height: 224px; }
      }

      .group2{
        img{
          height: 220px;
          margin-bottom: 43px;
        }
      }

      .group3{
        img{
          height: 150px;
          margin-bottom: 43px;
        }

        .img6{ margin-right: 0; }
        .img9{ margin-right: 0; }
      }
    }

    @include media-breakpoint-up(lg){
      .group1{
        margin-bottom: 13px;

        img{ height: 274px !important; }
      }

      .group2{
        img{
          height: 292px !important;
          margin-bottom: 43px;
        }
      }

      .group3{
        img{
          margin-bottom: 43px;
          height: 171px !important;
        }
        .img2{ margin-right: 18px; }
        .img4{ margin-left: 18px; }
      }
    }


  }
}

.block-catalyst{
  .img1 {
    left: -196px;

    @include media-breakpoint-up(md){
      height: 625px;
      left: auto;
    }
  }

  .blockquote{
    margin-left: -5px;
    margin-right: -5px;
    letter-spacing: -0.4px;
  }

  @include media-breakpoint-up(md){
    .container-560{ max-width: 560px + $container-gutter-width; }
    .container-670{ max-width: 670px + $container-gutter-width; }

    .blockquote{
      margin-left: auto;
      margin-right: auto;
      letter-spacing: normal;
    }
  }

  .places{
    display: flex;
    max-width: 375px;

    .row{ width: 100%; }

    .img{ width: 100%; }

    .text{
      padding: 27px 24px;
      color: $white;
      letter-spacing: 0.6px;
      font-weight: $font-weight-bold;
      text-align: left;
      line-height: 1.2222em;
    }

    @include media-breakpoint-up(sm){
      max-width: 630px;
    }

    @include media-breakpoint-up(md){
      .text{
        padding: 15px 20px 19px;
        font-size: rem-calc(20px);
      }
    }

    @include media-breakpoint-up(lg){
      max-width: 1258px;
      .row{ width: auto; }

      .img{ width: 315px; }
    }


  }

  .place{
    &.china{
      background-color: rgba(245, 145, 35, 0.95);
    }
    &.argentina{
      background-color: #425655;
    }
    &.uae{
      background-color: #441d67;
    }
    &.sydney{
      background-color: #a854a4;
    }
    &.andalucia{
      background-color: #4a90e2;
    }
    &.kyoto{
      background-color: #848a6d;
    }
    &.costarica{
      background-color:  rgba(80, 87, 71, 0.95);
    }
    &.greece{
      background-color: #142f6a;
    }
  }
}

.block-entrepreneur{
  margin-bottom: 13px;

  .container{
    max-width: 826px + $container-gutter-width;
  }

  .row{
    margin-left: -20px;
    margin-right: -20px;
  }

  .col-md{
    padding-left: 20px;
    padding-right: 20px;
  }

  .content{
    margin: 0 auto 35px;
    max-width: 290px;
    @include media-breakpoint-up(md){ margin-bottom: 50px; }
  }

  .img{
    width: 280px;
    margin-bottom: 31px;
  }

  .logo{
    margin-bottom: 18px;
    @include media-breakpoint-up(md){
      margin-bottom: 32px;
    }
  }

  @include media-breakpoint-up(md){

  }
}

.block-essence{
  .img1{
    @include media-breakpoint-up(md){
      height: 386px;
    }
  }

  .row{
    margin-left: -20px;
    margin-right: -20px;
  }

  .col-md{
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(md) {
    .container-660 {
      max-width: 670px + $container-gutter-width;
    }

    .container-826{
      max-width: 826px + $container-gutter-width;
    }
  }
}


.social-links{
  white-space:nowrap;

  .social-link{
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 50px;
    margin-right: 30px;

    &:last-child{ margin-right: 0; }
  }

  .twitter{
    background: $twitter;
    @include hover-focus-active { background: darken($twitter, $emphasized-link-hover-darken-percentage); }
  }

  .linkedin{
    background: $linkedin;
    @include hover-focus-active { background: darken($linkedin, $emphasized-link-hover-darken-percentage); }
  }

  .medium{
    background: $medium;
    @include hover-focus-active { background: darken($medium, $emphasized-link-hover-darken-percentage); }
  }
}

//blockquote footer spacings
@include media-breakpoint-up(lg){
  .blockquote-footer1{ padding-right: 30px; }
  .blockquote-footer2{ padding-right: 86px; }
  .blockquote-footer3{ padding-right: 167px; }
  .blockquote-footer4{ padding-right: 69px; }
  .blockquote-footer5{ padding-right: 114px; }
}
