html, body {
  height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-root;
}

body {
  min-width: $min-page-width;
  min-height: 100%;
  position: relative;

  @include media-breakpoint-up(md){
    font-size: $font-size-base-desktop;
    line-height: $line-height-base-desktop;
  }
}


//page header
.page-header {
  background: #ffffff;
  border-bottom: 1px solid #efefef;
  padding: 22px 25px 22px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: $zindex-sticky;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .page-logo{
    display: none;
    @include media-breakpoint-up(lg){
      display: block;
    }
  }

  .btn{ min-width: 130px; }

  @include media-breakpoint-up(md){
    padding: 38px 25px 31px;
  }

  @include media-breakpoint-up(lg){
    justify-content: space-between;
  }

  @include media-breakpoint-up(xl){
    padding: 48px 45px 41px;
  }
}


//!page header


//Page content
.page-wrapper{
  padding-top: 112px;
  @include media-breakpoint-up(md){
    padding-top: 185px;
  }
}

.page-container{
  max-width: 780px + $grid-gutter-width;
}
//!Page content

//Page footer
.page-footer{
  background: rgba(216, 216, 216, 0.4);;
  padding: 38px 0 30px;

  @include media-breakpoint-up(md){
    padding-bottom: 55px;
    padding-top: 33px;

    .container-600{ max-width: 600px + $container-gutter-width; }
  }

  .logo{
    margin-bottom: 24px;
    @include media-breakpoint-up(md){ margin-bottom: 0; }
  }

  .social-links{
    margin-bottom: 134px;
    @include media-breakpoint-up(md){ margin-bottom: 76px; }
  }

  .links{
    font-size: $font-size-sm;
  }
}

