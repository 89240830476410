.page-menu{
  background: #ffffff;
  display: none;
  text-transform: uppercase;
  font-size: rem-calc(20px);
  font-weight: $font-weight-thin;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.3s;

  ul{
    margin-bottom: 0;
  }

  li{
    margin-bottom: 20px;
  }

  .title{
    letter-spacing: 0.2px;
    font-size: 36px;
  }

  .about{
    letter-spacing: 3.39px;
  }

  a{
    @extend .text-dark;
  }

  @include media-breakpoint-up(lg){
    display: block;
    position: static;
    font-size: $font-size-sm;
    font-weight: $font-weight-light;
    opacity: 1;
    letter-spacing: 2px;

    li{
      display: inline-block;
      margin-bottom: 0;
      margin-right: calc(1px + 0.5vw);

      &:last-child{ margin-right: 0; }
    }

    .title{ display: none; }
  }

  @include media-breakpoint-up(lg){
  }

  @include media-breakpoint-up(xl){
    letter-spacing: 3.39px;
    margin-left: 13px;
    margin-top: -2px;

    li{
      margin-right: 21px;
    }
  }
}

.page-menu-open{
  .page-menu{
    display: flex;
    opacity: 1;
  }
}

.page-menu-toggle{
  position: absolute;
  left: $container-gutter-width*0.5;
  top: 19px;
  transform: rotate(0deg);
  z-index: 2;
  width: 36px;
  height: 36px;

  .header-fixed &, .page-menu-open &{
    position: fixed;
    top: 38px;
    margin-top: -19px;
  }

  @include media-breakpoint-up(md){
    top: 36px;
    .header-fixed &, .page-menu-open &{
      top: 55px ;

    }
  }

  @include media-breakpoint-up(lg){
    display: none;
  }

  //&:focus{ @include tab-focus(); }
  &:hover, &:active{
    &:focus{ outline: none; }
  }

  span {
    background: #000000;
    display: block;
    position: absolute;
    height: 2px;
    width: 36px;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  span:nth-child(1) { top: 7px; }
  span:nth-child(2),span:nth-child(3) { top: 17px; }
  span:nth-child(4) { top: 27px; }

  &:not(.collapsed){
    span:nth-child(1), span:nth-child(4) {
      top: 18px;
      width: 0;
      left: 50%;
    }

    span:nth-child(2), span:nth-child(3) {
      width: 38px;
      left: -1px;
    }
    span:nth-child(2){ transform: rotate(45deg); }
    span:nth-child(3){ transform: rotate(-45deg); }
  }
}
