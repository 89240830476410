// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff


// Container that the modal scrolls within
.modal {

}

.modal-dialog{
  max-width: 420px;
}

.modal-header{
  padding: 30px 29px 0;

  @include media-breakpoint-up(md){
    padding: 26px 29px 0;
  }
}

.modal-body{
  padding-top: 23px;
  padding-bottom: 100px;

  @include media-breakpoint-up(md){
    padding-left: 69px;
    padding-right: 65px;
  }
}

// Footer (for actions)
.modal-footer {
  padding: 0;
}


// Scale up the modal
@include media-breakpoint-up(xsm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    margin: $modal-dialog-margin-y-sm-up auto;
  }
}

@include media-breakpoint-up(md) {
  .modal-dialog {
    max-width: $modal-md;
  }
}
